<template>
    <div>
        <div class="container mx-auto my-6">
            <div class="module__back flex justify-center">
                <button @click="back" class="module__back__cta flex justify-center items-center">
                    <img src="@/assets/back.png" alt="">
                    <span class="flex items-center">Retour</span>
                </button>
                <div class="module__name flex items-center justify-center">{{ group.name }}</div>
            </div>
        </div>
        <div class="container mx-auto mb-4 home__courses__list">
            <div class="home__courses__list__items relative flex flex-wrap justify-center" v-if="thematics">
                <div v-for="(thematic, index) in thematics" :key="index"
                    class="py-2 m-1 rounded-2xl w-full"
                    :class="{
                        'w-3-3': thematic.modulesCount >= 3,
                        'w-2-3': thematic.modulesCount == 2,
                        'w-1-3': thematic.modulesCount == 1
                    }"
                    :style="{ backgroundColor: thematic.color }">
                    <div class="text-center" v-if="thematic.name"><h2>{{ thematic.name }}</h2></div>
                    <div class="text-center hidden md:block" v-else><h2>&nbsp;</h2></div>
                    <div class="thematic__modules relative flex flex-wrap justify-center p-1">
                        <div v-for="(module, index) in thematic.modules" :key="index"
                        class="w-full mb-4"
                        :class="{
                            'md:w-1/3': thematic.modulesCount >= 3,
                            'md:w-1/2': thematic.modulesCount == 2,
                            'md:w-full': thematic.modulesCount == 1
                        }">
                            <module-card :item="module" :theme-store="ThemeStore" :withoutBlocLabel="true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="home__courses__list__items position-relative py-4 flex flex-wrap justify-center" v-else-if="modules">
                <div v-for="(module, index) in modules" :key="index" class="w-full p-2 md:w-1/3">
                    <module-card :item="module" :theme-store="ThemeStore"/>
                </div>
            </div>
        </div>
        <div class="container mx-auto my-6" v-if="pagesCount > 1">
            <courses-pagination :pages="pagesCount" :current="currentPage" @page-updated="fetch"/>
        </div>
    </div>
</template>

<script>
import ModuleCard from './Card.vue';
import CoursesPagination from '@/components/Partials/CoursesPagination.vue'
import { useThemeStore } from "@/store/ThemeStore";

import AuthMixin from '@/mixins/authMixin'
import UIMixin from '@/mixins/uiMixin'

export default {
    mixins: [AuthMixin, UIMixin],
    components: {
        CoursesPagination,
        ModuleCard,
    },
    props: {
        filter: String,
        group: Object,
    },
    data () {
        const ThemeStore = useThemeStore();
        return {
            ThemeStore: ThemeStore,
            thematicRefs: null,
            fetching: false,
            thematics: [],
            modules: [],
            modulesCount: 0,
            modulesPerPage: 0,
            currentPage: 0,
            pagesCount: 0,
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        back () {
            this.$emit('back')
        },
        fetch(page) {
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/htmlModules'
            const accessToken = this.$cookies.get('access_token')
            this.fetching = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers, params: {
                courseRef: this.group.ref,
                client: this.ThemeStore.client,
                modulesPerPage: 6,
                page: page ?? 1
            } }).then(
                res => {
                    // detect if we get an array of modules
                    this.thematics = res.data.thematics
                    this.modules = res.data.modules
                    this.modulesCount = res.data.modulesCount
                    this.modulesPerPage = res.data.modulesPerPage
                    this.currentPage = res.data.currentPage
                    this.pagesCount = res.data.pagesCount
                    
                    this.fetching = false
                },
                error => {
                    this.fetching = false

                    if (error.response.status == 401) {
                        this.refreshToken(() => {
                            this.fetch(page)
                        })
                        
                        return
                    }
                }
            )
        },
    }
}
</script>

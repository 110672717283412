var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container mx-auto my-6"},[_c('div',{staticClass:"module__back flex justify-center"},[_c('button',{staticClass:"module__back__cta flex justify-center items-center",on:{"click":_vm.back}},[_c('img',{attrs:{"src":require("@/assets/back.png"),"alt":""}}),_c('span',{staticClass:"flex items-center"},[_vm._v("Retour")])]),_c('div',{staticClass:"module__name flex items-center justify-center"},[_vm._v(_vm._s(_vm.group.name))])])]),_c('div',{staticClass:"container mx-auto mb-4 home__courses__list"},[(_vm.thematics)?_c('div',{staticClass:"home__courses__list__items relative flex flex-wrap justify-center"},_vm._l((_vm.thematics),function(thematic,index){return _c('div',{key:index,staticClass:"py-2 m-1 rounded-2xl w-full",class:{
                    'w-3-3': thematic.modulesCount >= 3,
                    'w-2-3': thematic.modulesCount == 2,
                    'w-1-3': thematic.modulesCount == 1
                },style:({ backgroundColor: thematic.color })},[(thematic.name)?_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v(_vm._s(thematic.name))])]):_c('div',{staticClass:"text-center hidden md:block"},[_c('h2',[_vm._v(" ")])]),_c('div',{staticClass:"thematic__modules relative flex flex-wrap justify-center p-1"},_vm._l((thematic.modules),function(module,index){return _c('div',{key:index,staticClass:"w-full mb-4",class:{
                        'md:w-1/3': thematic.modulesCount >= 3,
                        'md:w-1/2': thematic.modulesCount == 2,
                        'md:w-full': thematic.modulesCount == 1
                    }},[_c('module-card',{attrs:{"item":module,"theme-store":_vm.ThemeStore,"withoutBlocLabel":true}})],1)}),0)])}),0):(_vm.modules)?_c('div',{staticClass:"home__courses__list__items position-relative py-4 flex flex-wrap justify-center"},_vm._l((_vm.modules),function(module,index){return _c('div',{key:index,staticClass:"w-full p-2 md:w-1/3"},[_c('module-card',{attrs:{"item":module,"theme-store":_vm.ThemeStore}})],1)}),0):_vm._e()]),(_vm.pagesCount > 1)?_c('div',{staticClass:"container mx-auto my-6"},[_c('courses-pagination',{attrs:{"pages":_vm.pagesCount,"current":_vm.currentPage},on:{"page-updated":_vm.fetch}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }